import React from "react"
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby"

const TestPage = () => {
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <Link to="#" className="btn btn-banner">Banner btn</Link>
                        <br />
                        <Link to="#" className="btn btn-white-outline">Btn White Outline</Link>
                        <br />
                        <Link to="#" className="btn btn-fill-primary">Btn Fill Primary</Link>
                        <br />
                        <Link to="#" className="btn btn-fill-white">Btn Fill White</Link>
                        <br />
                        <Link to="#" className="btn btn-dark">Btn Dark</Link>
                    </Col>
                </Row>
                <br />
            </Container>
        </>
    )
}

export default TestPage